<script setup>
import { copyVerses } from "@/composables/useApp";

const props = defineProps(["modelValue", "shareText"]);
const emit = defineEmits(["update:modelValue", "close"]);

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

function openShareTelegram() {
  close();
  openUrlOutside(`https://t.me/share/url?url=${props.shareText}`);
}

function openShareViber() {
  close();
  openUrlOutside(`viber://forward?text=${props.shareText}`);
}

function openShareWhatsapp() {
  close();
  openUrlOutside(`https://api.whatsapp.com/send?text=${props.shareText}`);
}
function openShareFacebook() {
  close();
  openUrlOutside(`https://newsroom.fb.com/text=${props.shareText}`);
}

function close() {
  dialog.value = false;
}
</script>
<template>
  <v-dialog
    bottom-sheet
    v-model="dialog"
    :options="{ width: '100vw', maxWidth: 'var(--screen-max-width)' }"
  >
    <div class="dialog-container">
      <!-- <div class="selection-button" @click="openShareViber()">
        <img src="/icons/share-ios-black.svg" alt="share icon" class="svg-icon" />
        <span class="ml-2">{{ transl("Share viber") }}</span>
      </div>
      <div class="selection-button" @click="openShareTelegram()">
        <img src="/icons/share-ios-black.svg" alt="share icon" class="svg-icon" />
        <span class="ml-2">{{ transl("Share telegram") }}</span>
      </div>
      <div class="selection-button mb-4" @click="openShareWhatsapp()">
        <img src="/icons/share-ios-black.svg" alt="share icon" class="svg-icon" />
        <span class="ml-2">{{ transl("Share whatsapp") }}</span>
      </div> -->

      <div class="title">{{ transl("Share") }}</div>
      <div class="icons">
        <div class="icon vb" @click="openShareViber()">
          <img src="/icons/social/viber.svg" alt="" />
        </div>
        <div class="icon tg" @click="openShareTelegram()">
          <img src="/icons/social/telegram.svg" alt="" />
        </div>
        <div class="icon wh" @click="openShareWhatsapp()">
          <img src="/icons/social/whatsapp.svg" alt="" />
        </div>
        <!-- <div class="icon fb" @click="openShareFacebook()">
          <img src="/icons/social/facebook.svg" alt="" />
        </div> -->
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>
.title {
  justify-content: center;
  padding: 20px 0px 4px;
  font-size: 27px;
  color: #3c3c3c;
}
.icon.wh {
  background-color: #25d366;
}
.icon.fb {
  background-color: #0866ff;
}
.icon.tg {
  background-color: #26a4e2;
}
.icon.vb {
  background-color: #665cac;
}

.tg img {
  transform: scale(0.8);
  margin-left: -4px;
}
.wh img {
  transform: scale(0.9);
  margin-left: 1px;
}
.fb img {
  transform: scale(0.8);
}
.icons {
  display: flex;
  justify-content: center;
  padding: 25px;
  gap: 26px;
  flex-wrap: wrap;
}
.icon {
  cursor: pointer;
  background: #313131;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
}
.icon:hover {
  transform: scale(1.1);
}
.icon img {
  width: 40px;
  height: 40px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(93deg) brightness(101%) contrast(103%);
}

.dialog-container {
  background-color: var(--root-white);
  padding-bottom: 40px;
}

.svg-icon {
  width: 25px;
}

.selection-details {
  width: 110px;
  font-size: 16px;
}
.selection-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 10px;
  flex-wrap: wrap;
}
.selection-button:hover {
  background-color: rgb(228, 228, 228);
}
</style>
