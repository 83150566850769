function openUrlInside(url: string) {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.click();   
}

function openUrlOutside(url: string) {
    window.open(url, '_blank', 'noreferrer');
}

export { 
    openUrlInside,
    openUrlOutside
}